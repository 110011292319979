import * as React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useEffect, useState} from 'react';

// @ts-ignore
declare let impGlobal: ImpGlobal;

interface ProductImageProps {
    altText: string;
    className?: string;
    containerClass?: string;
    lazy?: boolean;
    responsive?: boolean;
    rotation?: number;
    size: 50 | 67 | 75 | 92 | 96 | 120 | 400;
    src: string;
    srcIncludesImgThumbsPath?: boolean;
    style?: React.CSSProperties;
    useAbsoluteUrl?: boolean;
}

export const ProductImage = ({
    altText,
    className,
    containerClass,
    lazy = false,
    responsive = false,
    rotation,
    size,
    src,
    srcIncludesImgThumbsPath,
    style,
    useAbsoluteUrl = false,
}: ProductImageProps) => {
    const [absUrl, setAbsUrl] = useState(`//www.imperialsupplies.com`);

    // Set absUrl based on environment
    useEffect(() => {
        if (impGlobal && impGlobal.environment === `development`) {
            setAbsUrl(`//test.imperialsupplies.com`);
        }
    }, []);

    /**
     * Determines image src path
     */
    const getImageSrc = () => {
        let imgSrc = ``;

        // Add absUrl (primarily for supporting Storybook)
        if (useAbsoluteUrl) {
            imgSrc += absUrl;
        }

        // Add src (or image fallback if src is undefined)
        if (!srcIncludesImgThumbsPath) {
            imgSrc += `${getProductImageThumbsFolder()}${src || '/noimage.png'}`;
        } else {
            imgSrc += src ? `/${src}` : `${getProductImageThumbsFolder()}/noimage.png`;
        }

        // Return completed img src attribute
        return imgSrc;
    };

    /**
     * Returns desired ProductImageThumbs folder using ProductImage size prop
     */
    const getProductImageThumbsFolder = () => {
        return `/ProductImageThumbs${size < 120 ? 120 : size}`;
    };

    /**
     * Template
     */
    if (lazy) {
        return (
            <div className={containerClass}>
                <LazyLoadImage
                    alt={altText}
                    className={`${responsive ? 'img-fluid' : ''}${className ? ' ' + className : ''}`.trim()}
                    height={size}
                    src={getImageSrc()}
                    style={rotation ? {transform: `rotate(${rotation}deg)`} : {}}
                    width={size}
                />
            </div>
        );
    }
    return (
        <div
            className={containerClass}
            style={style}
        >
            <img
                alt={altText}
                className={`${responsive ? 'img-fluid' : ''}${className ? ' ' + className : ''}`.trim()}
                height={size}
                src={getImageSrc()}
                style={rotation ? {transform: `rotate(${rotation}deg)`} : {}}
                width={size}
            />
        </div>
    );
};
