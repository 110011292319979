import * as React from 'react';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Ref} from 'react';

export interface SelectOption {
    key: string;
    optGroup?: string;
    show?: boolean;
    value: string;
}

export type SelectProps = {
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    error?: string;
    id: string;
    isColumnPurpose?: boolean;
    label: string;
    name: string;
    onChange?: (key) => void;
    options: SelectOption[];
    ref?: Ref<unknown> | null;
    required?: boolean;
    selectClasses?: string;
    hidden?: boolean;
    truncate?: boolean;
    value?: string;
} & JSX.IntrinsicElements[`select`];

export const SelectComponent = (
    {
        className,
        defaultValue,
        id,
        isColumnPurpose = false,
        label,
        name,
        onChange,
        options,
        value,
        error,
        disabled,
        required,
        selectClasses,
        hidden,
        truncate = false,
    }: SelectProps,
    ref,
) => {
    const groupedOptions = options.reduce((acc, option) => {
        const accOptionGroup = acc[option?.optGroup] || [];
        return {
            ...acc,
            [option?.optGroup]: [...accOptionGroup, option],
        };
    }, {});

    return (
        <>
            <div
                className={`${truncate ? 'tw-truncate' : ''} ${isColumnPurpose ? '!tw-h-[38px]' : 'floating'} ${error ? ' mb-0' : ''}${
                    hidden ? ' d-none' : ''
                }${className ? ' ' + className : ''}`}
            >
                <select
                    defaultValue={defaultValue}
                    className={`${
                        isColumnPurpose
                            ? 'tw-block tw-w-full !tw-h-[38px] tw-py-1.5 tw-px-3 tw-text-base tw-font-normal tw-leading-6 tw-bg-white tw-border tw-border-gray-200 tw-rounded-sm tw-bg-clip-padding'
                            : 'custom-select float'
                    } ${error ? `error` : ``} ${selectClasses}`}
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={(e) => onChange(e)}
                    ref={ref}
                    required={required}
                    value={value}
                    hidden={hidden}
                >
                    {Object.keys(groupedOptions)[0] !== `undefined`
                        ? Object.keys(groupedOptions).map((optionGroupName, index) => (
                              <optgroup
                                  key={`${optionGroupName}_${index}`}
                                  label={optionGroupName.toUpperCase()}
                              >
                                  {groupedOptions[optionGroupName]
                                      .filter((option) => option.show !== false)
                                      .map((option, index2) => (
                                          <option
                                              key={`${option.value}_${index2}`}
                                              value={option.key}
                                          >
                                              {option.value}
                                          </option>
                                      ))}
                              </optgroup>
                          ))
                        : options
                              .filter((option) => option?.show !== false)
                              .map((option, index) => (
                                  <option
                                      key={`${option?.key}_${index}`}
                                      value={option?.key}
                                  >
                                      {option?.value}
                                  </option>
                              ))}
                </select>
                {error && (
                    <FontAwesomeIcon
                        className={`!tw-text-red-500 tw-z-10 !tw-text-xl tw-absolute tw-right-8 !tw-top-[15px]`}
                        icon={faExclamationCircle}
                    />
                )}
                <label
                    className={`${truncate ? 'tw-truncate tw-w-[calc(88%)]' : ''}`}
                    htmlFor={id}
                >
                    {label} {required && ` *`}
                </label>
            </div>
            {error && <label className="error caption form-label">{error}</label>}
        </>
    );
};
export const Select = React.forwardRef(SelectComponent);
